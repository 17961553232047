export function extractFirst(src?: string | string[]): string | null {
    if (!src) return null;
    if (Array.isArray(src)) {
        return src[0];
    }
    return src;
}

export function extractFirstOrUndefined(
    src?: string | string[]
): string | undefined {
    return extractFirst(src) ?? undefined;
}

export function extractString(src?: string | string[]): string | null {
    return extractFirst(src);
}

export function extractNumber(src?: string | string[]): number | undefined {
    const string = extractFirstOrUndefined(src);
    if (!string) return undefined;

    return Number.parseInt(string);
}
