import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import stylesHeadings from '@components/typography/headings.module.scss';

import styles from './link-control.module.scss';

export enum LinkControlSize {
    title = 'title', //gigantic/title
    h1 = 'h1', //h1
    h2 = 'h2', //h2
    h3 = 'h3', //h3
    h4 = 'h4', //h4
    h5 = 'h5', //h4
    text = 'text', //text
    caption = 'caption' //caption
}

export enum LinkControlTextColor {
    success = 'success',
    warning = 'warning',
    accent = 'accent',
    white = 'white',
    default = 'default'
}

export interface LinkControlProps {
    text: string;
    onClick?: () => void;
    variant?: LinkControlSize;
    color?: LinkControlTextColor;
    disabled?: boolean;
    disabledStyle?: boolean;
    onDarkBackground?: boolean;
    dataTestId?: string;
    bold?: boolean;
    icon?: ReactNode;
    noMargin?: boolean;
    lineDecorated?: boolean;
    className?: string;
}

const LinkControl: FC<LinkControlProps> = ({
    text,
    onClick,
    variant = 'title',
    color = LinkControlTextColor.default,
    disabled = false,
    disabledStyle = undefined,
    onDarkBackground = false,
    dataTestId,
    bold = false,
    icon,
    noMargin = false,
    lineDecorated = false,
    className
}) => {
    return (
        <span className={styles.container} data-testid={dataTestId}>
            {icon}
            <span
                className={classNames(
                    styles.linkText,
                    stylesHeadings[variant],
                    styles[color],
                    {
                        [styles.lineDecorated]: lineDecorated,
                        [styles.disabled]: disabled || disabledStyle,
                        [styles.onDarkBackground]: onDarkBackground,
                        [styles.onLightBackground]: !onDarkBackground,
                        [styles.bold]: bold,
                        [styles.noMargin]: noMargin,
                        [className ?? '']: className
                    }
                )}
                onClick={() => {
                    if (!disabled && onClick) {
                        onClick();
                    }
                }}
            >
                {text}
            </span>
        </span>
    );
};

export { LinkControl };
