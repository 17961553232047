import { FC, ReactNode } from 'react';

import { observer } from 'mobx-react-lite';

import Head from 'next/head';
import { useRouter } from 'next/router';

import classNames from 'classnames';

import { Select } from 'grommet';

import { AuthenticationBar } from '@components/hardware/security/authentication-bar';
import {
    LinkControl,
    LinkControlSize
} from '@components/typography/link-control';
import Title from '@components/typography/title';

import styles from './layout.module.scss';

type Props = {
    title?: string | JSX.Element;
    fullscreen?: boolean;
    tickers?: JSX.Element[];
    breadcrumbs?: JSX.Element;
    admin?: boolean;
    adminShowNavigationMenu?: boolean;
    noBackground?: boolean;
    blurTitle?: boolean;
    noCenter?: boolean;
    className?: string;
    children?: ReactNode;
};

const Layout: FC<Props> = observer(
    ({
        children,
        fullscreen,
        title,
        tickers,
        breadcrumbs,
        admin = false,
        adminShowNavigationMenu = false,
        noBackground = false,
        blurTitle = false,
        noCenter = false,
        className
    }) => {
        const router = useRouter();
        return (
            <div
                className={classNames({
                    [styles.fullscreen]: fullscreen,
                    [styles.container]: !noCenter,
                    [styles.noBackground]: noBackground,
                    [styles.admin]: admin,
                    [styles[className ?? '']]: className
                })}
            >
                {!fullscreen && (
                    <div
                        className={classNames(styles.title, {
                            [styles.blurTitle]: blurTitle
                        })}
                    >
                        {title && (
                            <Head>
                                {typeof title === 'string' && (
                                    <title>{title}</title>
                                )}
                            </Head>
                        )}
                        {typeof title === 'string' && (
                            <Title hideOverflow>{title}</Title>
                        )}
                        {typeof title !== 'string' && title}
                        {tickers &&
                            tickers.map((ticker, i) => (
                                <div key={i} className={styles.ticker}>
                                    {ticker}
                                </div>
                            ))}
                        {admin && (
                            <div className={styles.fixedBar}>
                                {admin && adminShowNavigationMenu && (
                                    <div className={styles.navigateTo}>
                                        <Select
                                            options={[
                                                {
                                                    to: '/admin/',
                                                    name: '🚦 Root Page'
                                                },
                                                {
                                                    to: '/admin/studios-and-conferences',
                                                    name: '🎥 Studios'
                                                },
                                                {
                                                    to: '/admin/timers',
                                                    name: '⏰ Timers'
                                                },
                                                {
                                                    to: '/admin/conference-schedule',
                                                    name: '👩‍🏭 Manage Conference Schedule'
                                                },
                                                {
                                                    to: '/admin/conference/creator',
                                                    name: '🔌 New Conference'
                                                },
                                                {
                                                    to: '/admin/my-conference-events',
                                                    name: '⛳ My conference events'
                                                },
                                                {
                                                    to: '/admin/unmapped-conference-events',
                                                    name: '👨‍🦯 Finding lost conference event'
                                                },
                                                {
                                                    to: '/me/my-roles',
                                                    name: '👁 Please tell me about yourself'
                                                }
                                            ]}
                                            placeholder="Navigate to"
                                            labelKey="name"
                                            valueLabel={
                                                <LinkControl
                                                    text="Navigate to"
                                                    variant={
                                                        LinkControlSize.text
                                                    }
                                                    noMargin
                                                />
                                            }
                                            icon={false}
                                            onChange={({ value }) =>
                                                router.push(value.to)
                                            }
                                            plain
                                        />
                                    </div>
                                )}
                                <AuthenticationBar right />
                            </div>
                        )}
                        <div className={styles.descriptionLine}>
                            {breadcrumbs}
                        </div>
                    </div>
                )}
                {children}
            </div>
        );
    }
);

export default Layout;
