import { observer } from 'mobx-react-lite';

import { useRouter } from 'next/router';

import classNames from 'classnames';

import { Button } from 'grommet';

import { extractFirstOrUndefined } from '@util/arg-utils';

import { useRootStore } from '@store/root-store-helpers';

import Text from '@components/typography/text';

import styles from './authentication-bar.module.scss';

type Props = {
    noRedirect?: boolean;
    right?: boolean;
};
export const AuthenticationBar = observer<Props>(
    ({ right = false, noRedirect = false }) => {
        const rootStore = useRootStore();
        const router = useRouter();
        const { isAuthenticated, authenticatedEmail, authenticationInFlight } =
            rootStore;

        const authButtonComponent = isAuthenticated ? (
            <Button
                fill="horizontal"
                label={'Sign Out'}
                onClick={() => {
                    if (noRedirect) {
                        rootStore.signOut(
                            extractFirstOrUndefined(router.query.callbackUrl)
                        );
                    } else {
                        router.push(
                            `/signin?callbackUrl=${router.basePath}${router.asPath}`
                        );
                    }
                }}
            />
        ) : (
            <Button
                fill="horizontal"
                label={'Sign In'}
                primary
                onClick={() => {
                    if (noRedirect) {
                        rootStore.signIn(
                            extractFirstOrUndefined(router.query.callbackUrl)
                        );
                    } else {
                        router.push('/signin');
                    }
                }}
            />
        );

        const shouldShowSignInControls =
            isAuthenticated && !authenticationInFlight;
        return (
            <div
                className={classNames(styles.container, {
                    [styles.container__unauth]: !isAuthenticated
                })}
            >
                <div
                    className={classNames(styles.authButton, {
                        [styles.alignRight]: right
                    })}
                >
                    {!authenticationInFlight && authButtonComponent}
                    {authenticationInFlight && (
                        <Button
                            fill="horizontal"
                            label={'Loading...'}
                            primary
                        />
                    )}
                </div>
                <div className={styles.userIdentifier}>
                    <Text>
                        {shouldShowSignInControls
                            ? authenticatedEmail
                            : 'Not authenticated'}
                    </Text>
                </div>
            </div>
        );
    }
);
