import { FC } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { initConferenceEvent } from '@actions/api/conference-info';
import { Button } from 'grommet';

import { getPublicConfiguration } from '@config/universal-config';

import { log } from '@online/speaker-room-sdk/utils/log';

import { ArtBusinessWoman } from '@components/art/art-business-woman';
import { ArtCat } from '@components/art/art-cat';
import { PeopleCanCallArt } from '@components/art/people-can-call-art';
import { Box } from '@components/layout/box';
import Layout from '@components/layout/layout';
import { useNotifications } from '@components/layout/notifications-store';

import styles from './index.module.scss';

export const IndexPage: FC = () => {
    const router = useRouter();
    const notificationsStore = useNotifications();

    return (
        <>
            <div className={styles.imageContainer}>
                <PeopleCanCallArt />
            </div>
            <Layout title="Thinking about Video Call?" noBackground>
                <div className={styles.content}>
                    <Box
                        pad={{ vertical: 'xlarge' }}
                        className={styles.instantMeetingContainer}
                    >
                        <div className={styles.cat}>
                            <ArtCat />
                        </div>
                        <Button
                            primary
                            label="Instant meeting"
                            size="large"
                            className={styles.glow}
                            style={{ zIndex: 1 }}
                            onClick={() => {
                                initConferenceEvent({})
                                    .then((status) => {
                                        const conferenceId =
                                            status?.conferenceId;
                                        if (!conferenceId) {
                                            notificationsStore.critical({
                                                disappearAfterMs: 10_000,
                                                title: "Can't create new call",
                                                text: 'Not enough permissions'
                                            });
                                            return;
                                        }
                                        router.push({
                                            pathname:
                                                '/conference/[conferenceId]/configure-devices',
                                            query: { conferenceId }
                                        });
                                        return conferenceId;
                                    })
                                    .catch((reason) => {
                                        log.error(reason);
                                        throw new Error(
                                            "Can't initialize new conference"
                                        );
                                    });
                            }}
                        />
                    </Box>
                    <Box
                        pad={{ vertical: 'small' }}
                        className={styles.conferenceEventContainer}
                    >
                        <div className={styles.woman}>
                            <ArtBusinessWoman />
                        </div>
                        <Link
                            href="/admin/conference/creator"
                            passHref
                            style={{ zIndex: 1 }}
                        >
                            <Button
                                label="New conference event"
                                size="large"
                                className={styles.glow}
                            />
                        </Link>
                    </Box>
                </div>
            </Layout>
        </>
    );
};

export const getServerSideProps = () => {
    return {
        props: {
            publicConfiguration: getPublicConfiguration()
        }
    };
};

export default IndexPage;
