import { FC } from 'react';

import classNames from 'classnames';

import { HeadingProps } from '@components/typography/headings';

import styles from './headings.module.scss';

const Title: FC<HeadingProps> = ({
    children,
    onClick,
    pointer,
    noMargin = false,
    noWrap = false,
    color = 'default',
    upper = false,
    lower = false,
    baseline = false,
    className,
    hideOverflow = false
}) => {
    return (
        <h1
            onClick={onClick}
            className={classNames(
                styles.container,
                styles.title,
                styles[color],
                {
                    [styles.pointer]: pointer,
                    [styles.noMargin]: noMargin,
                    [styles.upper]: upper,
                    [styles.lower]: lower,
                    [styles.baseline]: baseline,
                    [styles.noWrap]: noWrap,
                    [styles.hideOverflow]: hideOverflow,
                    [className ?? '']: className
                }
            )}
        >
            {children}
        </h1>
    );
};

export default Title;
